<template>
    <div class="row">
        <Toast :baseZIndex="9999" />
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <b-modal ref="profile-update-modal" hide-footer :no-close-on-backdrop="true" id="modalmd"
                        title="Edit Profile" size="md" :footer-bg-variant="'info'">
                        <div class="col-md-12">
                            <form @submit.prevent="saveProfile">
                                <b-form-group label="" label-for="name">
                                    <b-form-input v-model="name" type="text" id="name"
                                        placeholder="Enter User Name"></b-form-input>
                                </b-form-group>
                                <b-form-group label="" label-for="input8">
                                    <b-form-file accept=".png, .jpg, .jpeg" v-model="file" id="inpu8" placeholder="Choose profile..."></b-form-file>
                                </b-form-group>
                                <b-button type="submit" variant="success" class="mr-2">Save</b-button>
                            </form>
                        </div>
                    </b-modal>
                    <b-modal ref="changepassword-modal" hide-footer :no-close-on-backdrop="true" id="changePassword-modal"
                        title="Change Account Password" size="md" :footer-bg-variant="'info'">
                        <div class="col-md-12">
                            <form @submit.prevent="updatePassword">
                                <b-form-group label="" label-for="currentPassword">
                                    <b-form-input required v-model="currentPassword" type="password" id="currentPassword"
                                        placeholder="Current Password"></b-form-input>
                                </b-form-group>
                                <b-form-group label="" label-for="newPassword">
                                    <b-form-input required v-model="newPassword" type="password" id="newPassword"
                                        placeholder="New Password"></b-form-input>
                                </b-form-group>
                                <b-form-group label="" label-for="confirmPassword">
                                    <b-form-input required v-model="confirmPassword" type="password" id="confirmPassword"
                                        placeholder="Confirm Password"></b-form-input>
                                </b-form-group>
                                <b-button type="submit" variant="success" class="mr-2">Save</b-button>
                            </form>
                        </div>
                    </b-modal>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-lg-flex justify-content-between">
                                <div>
                                    <h3 class="mt-3 mt-lg-0">{{ getCurrentUser.userName }}</h3>
                                    <div class="d-flex align-items-center">
                                        <h5 class="mb-0 mr-2 text-muted mt-3 mt-lg-0">{{ getCurrentUser.email }}</h5>
                                    </div>
                                </div>
                                <div class="mt-3 mt-lg-0">
                                    <b-button v-b-modal.modalmd class="btn btn-gradient-success btn-rounded btn-icon">
                                        <i class="fa fa-pencil-square-o"></i>
                                    </b-button>
                                    <b-button v-b-modal.changePassword-modal class="btn btn-gradient-info btn-fw">Change Password</b-button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Toast from "primevue/toast";

export default {
    components: {
        Toast
    },
    data() {
        return {
            name: null,
            file: null,
            currentPassword: null,
            newPassword: null,
            confirmPassword: null
        }
    },
    computed: {
        ...mapGetters(['getCurrentUser'])
    },
    methods: {
        ...mapActions(['updateUserProfile', 'changePassword', 'logout']),
        async saveProfile() {

            let formData = new FormData();
            formData.append('name', this.name);

            if(this.file !== null){                
                formData.append('image', this.file);
            }
            await this.updateUserProfile(formData)
            this.$refs["profile-update-modal"].hide();
            this.$toast.add({ severity: 'success', summary: `Profile Updated Successfully.`, detail: 'Hurray!', life: 3000 });
        },

        async updatePassword() {
            if(this.newPassword != this.confirmPassword){
                this.$toast.add({ severity: 'error', summary: `Password Not Confirmed.`, detail: 'oOps!', life: 3000 });                
            }else{
                let response = await this.changePassword({currentPassword: this.currentPassword, newPassword: this.newPassword});
                if(response.status == 200){
                    this.$refs["changepassword-modal"].hide();
                    this.$toast.add({ severity: 'success', summary: response.data.message, detail: 'Hurray!', life: 3000 });     
                    // this.logout();
                }else{
                    this.$toast.add({ severity: 'error', summary: response.data.message, detail: 'oOps!', life: 3000 });     

                }
            }
        }
    },
    mounted() {
        this.name = this.getCurrentUser.userName
    }
}
</script>